<template>
  <b-modal
    size="lg"
    id="sc-likely"
    class="advisor-info-modal"
    title="Next Most Likely Solution"
    ok-only
  >
    <p>
      The Next Most Likely Solution recommendation is generated for each section
      of the Retail Services and Business Services offering. For example, Retail
      Payments is a section of Retail Services. The next most likely solution
      recommendation is the highest percentage solution offered by the selected
      peer group that is NOT offered by the subject financial institution. As
      such, changing peer groups might change the recommendation for the
      institution. Prioritizing where to focus in services expansion is a
      critically important process for any institution. {{ siteName }}’s
      in-depth, constantly evolving services hierarchy can ensure that any
      institution can identify where its offering lags the services of peer
      institutions.
    </p>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SolutionHierarchyNextSolutionModal',
  computed: {
    ...mapState({ partnerSite: state => state.partnerSite }),

    siteName() {
      return 'FI Navigator'
    }
  }
}
</script>
